import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/Contact";

const FourthPage = () => (
  <Layout>
    <SEO title="Kontakt" />
    <Contact />
  </Layout>
)

export default FourthPage
