import React from "react"
import "./Contact.css"

import img from "../images/ulf_contact.jpg"

const Contact = () => {
  return (
    <div className="Contact">
      <img src={img} className="Contact-img" alt="Pictures of Ulf" />
      <div className="Contact-info">
        <p>
          <b>UL JURIDIK</b>
        </p>
        <a href="mailto:ulf.lindegard@uljuridik.se">
          ulf.lindegard@uljuridik.se
        </a>
        <a href="tel:0706894829">0706-894829</a>
      </div>
    </div>
  )
}

export default Contact
